import {GlobalService} from '../../services/global.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {ToastsService} from '../../services/toasts.service';
import {MatDialog} from '@angular/material/dialog';
import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {ProjectsService} from '../../services/projects.service';
import {GroupsService} from '../../services/groups.service';
import {FirebaseService} from '../../services/firebase.service';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

    appt_types;

    gfe_templates = [
        {name: 'N/A', id: null},
        {name: 'New Prescriber', id: 'KGpP7mR71Yzp2bFCDvZz'},
        {name: 'Return Prescriber', id: 'DXJabusJFgKz4sv22NxI'},
        {name: 'New Therapy', id: 'lGjKGJw9DJw8RRtwp1uD'},
        {name: 'Return Therapy', id: 'TVSDGiSHnKl81r4C9xQv'},
        {name: 'Family or Couples Therapy', id: 'oN9gl0dT4fh1kgcPKDhQ'},
        {name: 'Group Therapy', id: 'YKVxUQSXt2Ycbz40XuYV'},
        {name: 'Testing', id: 'uIrBCzi6h1GvpnnRPN9b'},
        {name: 'TMS', id: 'we3Hk6R5C0ZZEUZAxyA2'},
        {name: 'Prompt Pay - Family or Couples Therapy', id: '8gKqozyAgW3N4XoIE2oO'},
        {name: 'Prompt Pay - Return Therapy', id: 'cmlSeKkImck69LYLKloc'},
        {name: 'Prompt Pay - Group Therapy', id: 'aYrYrizWdCe07tzkbmYh'},
        {name: 'Prompt Pay - Return Prescriber', id: 'tBeFDtcJNTKbRI8Hbfi6'},
        {name: 'IOP', id: 'EVsOwCs0YQ2n2qH8Y0h9'},
        {name: 'PHP', id: 'MS0PM3vcXANQD0lEsY4m'},
    ];

    constructor(private router: Router,
                public globalService: GlobalService,
                private groupsService: GroupsService,
                private projectsService: ProjectsService,
                public dialog: MatDialog,
                private http: HttpClient,
                private afs: AngularFirestore,
                private toastsService: ToastsService,
                private firebaseService: FirebaseService) {
    }

    ngOnInit() {
        const apptTypes = this.firebaseService.firebaseAmd.collection<any>('amd').doc('appointment-types-details');
        apptTypes.valueChanges().subscribe((appt_types) => {
            console.log(appt_types);
            this.appt_types = appt_types;
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
        });
    }

    saveApptTypeSetting(office_code, id, setting, value) {
        console.log(office_code, id, setting, value);
        try {
            const itemDoc = this.firebaseService.firebaseAmd.doc<any>('amd/appointment-types-details');
            itemDoc.update({[`${office_code}.${id}.${setting}`]: value});
        } catch (e) {
            this.toastsService.showSnackBar(e, 'error', 5000);
        }
    }

    // saveGroupDisplayName(group) {
    //     try {
    //         const itemDoc = this.afs.doc<any>('microsoft-groups/' + group.email);
    //         itemDoc.update({'displayName': group.displayName});
    //     } catch (e) {
    //         this.toastsService.showSnackBar(e, 'error', 5000);
    //     }
    // }
    //
    // saveGroupToggle(group) {
    //     try {
    //         const itemDoc = this.afs.doc<any>('microsoft-groups/' + group.email);
    //         itemDoc.update({'active': !group['active']});
    //     } catch (e) {
    //         this.toastsService.showSnackBar(e, 'error', 5000);
    //     }
    // }

}
