import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {AngularFireAuthGuard, customClaims, hasCustomClaim, redirectLoggedInTo, redirectUnauthorizedTo} from '@angular/fire/auth-guard';
import {ProjectsComponent} from './projects/projects.component';
import {ProjectComponent} from './projects/project/project.component';
import {GroupsComponent} from './groups/groups.component';
import {HelpComponent} from './help/help.component';
import {AnnouncementsComponent} from './announcements/announcements.component';
import {pipe} from 'rxjs';
import {map} from 'rxjs/operators';
import {KanbanComponent} from './kanban/kanban.component';
import {ReferralsComponent} from './referrals/referrals.component';
import {HomeComponent} from './home/home.component';
import {SettingsComponent} from './amd/settings/settings.component';
import {CaseloadsComponent} from './caseloads/caseloads.component';
import {TasksComponent} from './referrals/tasks/tasks.component';
import {OnboardingComponent} from './amd/onboarding/onboarding.component';

const adminOnly = () => hasCustomClaim('admin');
const isAdmin = () => pipe(customClaims, map(claims =>
    claims.admin === true ? claims.admin : ['/projects']
));
const redirectLoggedInToHome = () => redirectLoggedInTo(['home']);
const redirectLoggedInToProjects = () => redirectLoggedInTo(['projects']);
const redirectLoggedInToRecruiting = () => redirectLoggedInTo(['recruiting']);

const redirectLoggedInToReferrals = () => redirectLoggedInTo(['referrals']);

const redirectUnauthorizedToLogin = (afterLoginRedirect) => {
    const path = afterLoginRedirect?.url[0].path;
    if (path === 'projects') {
        if (afterLoginRedirect?.params?.id && afterLoginRedirect?.params?.taskId) {
            return redirectUnauthorizedTo([`login`, `projects`, `${afterLoginRedirect?.params?.id}`, `${afterLoginRedirect?.params?.taskId}`]);
        } else if (afterLoginRedirect?.params?.id) {
            return redirectUnauthorizedTo([`login`, `projects`, `${afterLoginRedirect?.params?.id}`]);
        }
    } else if (path === 'recruiting') {
        if (afterLoginRedirect?.params?.type) {
            return redirectUnauthorizedTo([`login`, `recruiting`, `${afterLoginRedirect?.params?.type}`]);
        }
    } else if (path === 'referrals') {
        if (afterLoginRedirect?.params?.id) {
            return redirectUnauthorizedTo([`login`, `referrals`, `${afterLoginRedirect?.params?.id}`]);
        }
    }

    return redirectUnauthorizedTo(['login']);
};

const routes: Routes = [
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AngularFireAuthGuard],
        data: {authGuardPipe: redirectUnauthorizedToLogin}
    },
    {
        path: 'help',
        component: HelpComponent,
        canActivate: [AngularFireAuthGuard],
        data: {authGuardPipe: redirectUnauthorizedToLogin}
    },
    {
        path: 'amd/good-faith-estimates',
        component: SettingsComponent,
        canActivate: [AngularFireAuthGuard],
        data: {authGuardPipe: isAdmin}
    },
    {
        path: 'amd/onboarding',
        component: OnboardingComponent,
        canActivate: [AngularFireAuthGuard],
        data: {authGuardPipe: isAdmin}
    },
    {
        path: 'groups',
        component: GroupsComponent,
        canActivate: [AngularFireAuthGuard],
        data: {authGuardPipe: isAdmin}
    },
    {
        path: 'ems',
        component: AnnouncementsComponent,
        canActivate: [AngularFireAuthGuard],
        data: {authGuardPipe: isAdmin}
    },
    {
        path: 'recruiting',
        component: KanbanComponent,
        canActivate: [AngularFireAuthGuard],
        data: {authGuardPipe: redirectUnauthorizedToLogin}
    },
    {
        path: 'recruiting/:type',
        component: KanbanComponent,
        canActivate: [AngularFireAuthGuard],
        data: {authGuardPipe: redirectUnauthorizedToLogin}
    },
    {
        path: 'referrals',
        component: ReferralsComponent,
        canActivate: [AngularFireAuthGuard],
        data: {authGuardPipe: redirectUnauthorizedToLogin}
    },
    {
        path: 'tasks',
        component: TasksComponent,
        canActivate: [AngularFireAuthGuard],
        data: {authGuardPipe: redirectUnauthorizedToLogin}
    },
    {
        path: 'referrals/:id',
        component: ReferralsComponent,
        canActivate: [AngularFireAuthGuard],
        data: {authGuardPipe: redirectUnauthorizedToLogin}
    },
    {
        path: 'projects',
        component: ProjectsComponent,
        canActivate: [AngularFireAuthGuard],
        data: {authGuardPipe: redirectUnauthorizedToLogin}
    },
    {
        path: 'projects/:id',
        component: ProjectComponent,
        canActivate: [AngularFireAuthGuard],
        data: {authGuardPipe: redirectUnauthorizedToLogin}
    },
    {
        path: 'projects/:id/:taskId',
        component: ProjectComponent,
        canActivate: [AngularFireAuthGuard],
        data: {authGuardPipe: redirectUnauthorizedToLogin}
    },
    {
        path: 'caseloads',
        component: CaseloadsComponent,
        canActivate: [AngularFireAuthGuard],
        data: {authGuardPipe: redirectUnauthorizedToLogin}
    },
    {path: 'login', component: LoginComponent, canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectLoggedInToHome}},
    {path: 'login/projects/:id', component: LoginComponent, canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectLoggedInToProjects}},
    {path: 'login/projects/:id/:taskId', component: LoginComponent, canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectLoggedInToProjects}},
    {path: 'login/recruiting/:type', component: LoginComponent, canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectLoggedInToRecruiting}},
    {path: 'login/referrals/:id', component: LoginComponent, canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectLoggedInToReferrals}},
    {path: '**', redirectTo: '/login'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
