import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProjectsService} from '../../services/projects.service';
import {GlobalService} from '../../services/global.service';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: 'confirm-dialog.html',
    styleUrls: ['confirm-dialog.scss']
})
export class ConfirmDialogComponent implements OnInit {

    constructor(
        public projectsService: ProjectsService,
        public globalService: GlobalService,
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        // console.log(this.data);
    }

    ngOnInit() {
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    closeDialog() {
        this.dialogRef.close(true);
    }

}
