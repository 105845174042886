import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ProjectsService} from '../../services/projects.service';
import {GlobalService} from '../../services/global.service';
import {ReferralsService} from '../../services/referrals.service';
import {GroupsService} from '../../services/groups.service';
import {AutoCompleteValidator} from '../../autocomplete-validator';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AuthService} from '../../auth/auth.service';

@Component({
    selector: 'app-substatus-confirm-dialog',
    templateUrl: 'substatus-confirm-dialog.html',
    styleUrls: ['substatus-confirm-dialog.scss']
})
export class SubstatusConfirmDialogComponent implements OnInit {

    form: FormGroup;
    task: FormGroup;
    details_schema;
    details_values;
    form_label = 'Additional Details';
    show_create_task = false;
    task_creation_requested = false;
    taskInvalid = false;
    filteredUsers: Observable<any[]>;

    constructor(
        public projectsService: ProjectsService,
        private authService: AuthService,
        public groupsService: GroupsService,
        public globalService: GlobalService,
        public referralsService: ReferralsService,
        public dialogRef: MatDialogRef<SubstatusConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder) {
        // console.log(this.data);
        this.details_schema = this.data.details_schema;
        this.details_values = this.data.details_values ?? {};
        this.show_create_task = this.data.show_create_task ?? false;
    }

    ngOnInit() {
        // build form using this.details_schema
        this.form = this._formBuilder.group({});
        this.details_schema.forEach(field => {
            const validators = [];
            if (field.type === 'user') {
                validators.push(AutoCompleteValidator.mustBeInSimpleList(this.groupsService.allUsers));
            }
            if (field.required) {
                if (field.type === 'checkbox') {
                    validators.push(Validators.requiredTrue);
                } else {
                    validators.push(Validators.required);
                }
            } else {
                validators.push(Validators.nullValidator);
            }

            this.form.addControl(
                field.name,
                new FormControl(
                    this.details_values[field.name] ?? '',
                    validators
                )
            );

            if (field.type === 'user') {
                // TODO this won't work if more than one User field exists per popup form
                this.filteredUsers = this.form.get(field.name).valueChanges
                    .pipe(
                        startWith(''),
                        map(value => this.groupsService.filterUsers(value))
                    );
            }
        });
        // set default values for any "date" type fields, taking into account that sometimes the value is a firestore Timestamp and sometimes it's a Date object
        this.details_schema.forEach(field => {
            if (field.type === 'date') {
                let default_value_formatted;
                if (this.details_values[field.name]?.toDate) {
                    default_value_formatted = this.details_values[field.name].toDate();
                } else {
                    default_value_formatted = this.details_values[field.name];
                }

                if (!this.details_values[field.name]) {
                    default_value_formatted = new Date();
                }
                if (field?.is_dropdown === true) {
                    default_value_formatted = this.referralsService.dueDateOptions[2].value;
                }


                this.form.get(field.name).setValue(default_value_formatted);
            }

            // if field.type is 'select' and 'default_first_option' is true, set the first option as the default value
            if (field.type === 'select' && field.default_first_option) {
                this.form.get(field.name).setValue(field.options[0]);
            }

            // if field.type is 'user', default to the current user if no value is set
            if (field.type === 'user' && !this.details_values[field.name]) {
                this.form.get(field.name).setValue(this.authService.user.email);
            }
        });

        if (this.show_create_task) {
            this.initTaskForm();
        }
    }

    initTaskForm() {
        this.task = this._formBuilder.group({
            type: ['To-Do', Validators.required],
            due_date: [this.referralsService.dueDateOptions[2].value, Validators.required],
            assigned_to: [this.authService.user.email, AutoCompleteValidator.mustBeInSimpleList(this.groupsService.allUsers)],
            notes: [''],
            completed: [false],
        });
    }

    onTaskFormGroupChanged(taskEvent) {
        this.task.patchValue(taskEvent.value);
        // if child task form is invalid, disable this form's submit button
        this.taskInvalid = !taskEvent.valid;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog() {
        this.dialogRef.close({
            details: this.form.getRawValue(),
            task: this.task_creation_requested ? this.task.getRawValue() : null
        });
    }

}
