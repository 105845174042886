export const environment = {
    production: true,
    firebase: {
        apiKey: 'AIzaSyAgNvwR7dPKil7rSc9moxxtjI80zph2cfQ',
        authDomain: 'projects.claritychi.com',
        databaseURL: 'https://clarity-clinic-onboarding.firebaseio.com',
        projectId: 'clarity-clinic-onboarding',
        storageBucket: 'clarity-clinic-onboarding.appspot.com',
        messagingSenderId: '489703274339',
        appId: '1:489703274339:web:7d732dcc5e69920e050556'
    },
    amdFirebase: {
        apiKey: 'AIzaSyDTKY7veO6i4UjLL-0nFaM16L3Igq-dmzQ',
        authDomain: 'projects.claritychi.com',
        databaseURL: 'https://clarity-clinic-amd.firebaseio.com',
        projectId: 'clarity-clinic-amd',
        storageBucket: 'clarity-clinic-amd.appspot.com',
        messagingSenderId: '847886046863',
        appId: '1:847886046863:web:ac4f4efa0266c6e98dc2fa',
        measurementId: 'G-7QBKW0F9N5'
    },
    apiBaseUrl: 'https://us-central1-clarity-clinic-onboarding.cloudfunctions.net/api/',
    cloudFunctionsBaseUrl: 'https://us-central1-clarity-clinic-onboarding.cloudfunctions.net/',
    amdApiBaseUrl: 'https://us-central1-clarity-clinic-amd.cloudfunctions.net/',
    websiteBaseUrl: 'https://claritychi.com/wp-json/v1/',
    facilities: {
        '179': 'Arlington Heights',
        '188': 'Evanston',
        '190': 'Lakeview - Belmont',
        '181': 'Lakeview - Broadway',
        '186': 'Loop',
        '189': 'Mokena',
        '184': 'River North',
    },
    officeCodeFacilities: {
        '133003': {
            '188': 'Evanston',
            '190': 'Lakeview - Belmont',
            '181': 'Lakeview - Broadway',
            '186': 'Loop',
            '189': 'Mokena',
            '184': 'River North',
        },
        '138325': {
            '179': 'Arlington Heights',
        },
    },
    sanity: {
        projectId: 'jmqfvlrp',
        dataset: 'production',
        useCdn: true,
    },
};
