import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ReferralsService} from '../../services/referrals.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {AuthService} from '../../auth/auth.service';
import {GroupsService} from '../../services/groups.service';
import {AutoCompleteValidator} from '../../autocomplete-validator';

@Component({
    selector: 'app-create-task',
    templateUrl: './create-task.component.html',
    styleUrls: ['./create-task.component.scss']
})
export class CreateTaskComponent implements OnInit, OnChanges {

    initialized = false;
    isDateTypeRelative = true;

    @Output() taskFormGroupChanged = new EventEmitter<any>();

    taskFormGroup: FormGroup;
    filteredUsers: Observable<any[]>;

    constructor(
        private _formBuilder: FormBuilder,
        public referralsService: ReferralsService,
        private authService: AuthService,
        public groupsService: GroupsService) {
    }

    changeDateType() {
        this.isDateTypeRelative = !this.isDateTypeRelative;
        if (this.isDateTypeRelative) {
            this.taskFormGroup.get('due_date').setValue(this.referralsService.dueDateOptions[2].value);
        }
    }

    ngOnInit() {
        this.taskFormGroup = this._formBuilder.group({
            type: ['To-Do', Validators.required],
            due_date: [this.referralsService.dueDateOptions[2].value, Validators.required],
            assigned_to: [this.authService.user.email, AutoCompleteValidator.mustBeInSimpleList(this.groupsService.allUsers)],
            notes: [''],
            completed: [false],
        });

        this.filteredUsers = this.taskFormGroup.get('assigned_to').valueChanges
            .pipe(
                startWith(''),
                map(value => this.groupsService.filterUsers(value))
            );

        this.taskFormGroup.valueChanges
            .subscribe(() => this.taskFormGroupChanged.emit({
                value: this.taskFormGroup.value,
                valid: this.taskFormGroup.valid
            }));
        this.initialized = true;
    }

    ngOnChanges() {
        if (!this.initialized) {
            return;
        }

        // TODO should we disable form as parent component checkbox changes?
        // if (this.disabled) {
        //     this.taskFormGroup.disable();
        // } else {
        //     this.taskFormGroup.enable();
        // }
    }
}
