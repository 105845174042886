import {GlobalService} from '../../services/global.service';
import {ToastsService} from '../../services/toasts.service';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {FirebaseService} from '../../services/firebase.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment.prod';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog';

@Component({
    selector: 'app-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {

    profiles_by_id = {};
    providerUpdate: FormGroup;

    // loaded from firestore
    pages = {};
    roles = {};
    appt_types_to_roles = {};
    number_of_appt_types_to_update = 0;

    facilities = environment.officeCodeFacilities;
    openConfirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

    constructor(private router: Router,
                public globalService: GlobalService,
                public dialog: MatDialog,
                private http: HttpClient,
                private toastsService: ToastsService,
                private _formBuilder: FormBuilder,
                private firebaseService: FirebaseService) {
    }

    resetProviderUpdateForm() {
        this.providerUpdate.reset();
        this.providerUpdate.controls['roles'].disable();
        this.providerUpdate.controls['create_column'].disable();
        this.providerUpdate.controls['merge_roles'].disable();
        this.providerUpdate.controls['column_name'].disable();
        this.providerUpdate.controls['page_id'].disable();
        this.providerUpdate.controls['facility_id'].disable();
    }

    ngOnInit() {
        this.providerUpdate = this._formBuilder.group({
            office_code: [null, Validators.required],
            profile_id: [null, Validators.required],
            roles: [{value: null, disabled: true}, Validators.required],
            merge_roles: [{value: false, disabled: true}, Validators.required],
            create_column: [{value: false, disabled: true}, Validators.required],
            facility_id: [{value: null, disabled: true}],
            page_id: [{value: null, disabled: true}],
            column_name: [{value: '', disabled: true}],
        });

        const profiles = this.firebaseService.firebaseAmd.collection<any>('amd').doc('profiles_by_id');
        profiles.valueChanges().subscribe((profiles_by_id) => {
            // console.log(profiles_by_id);
            const active_profiles = {};

            Object.keys(profiles_by_id).forEach(office_code => {
                Object.keys(profiles_by_id[office_code]).forEach(profile_id => {
                    if (profiles_by_id[office_code][profile_id].status === 'ACTIVE') {
                        if (!active_profiles[office_code]) {
                            active_profiles[office_code] = {};
                        }
                        active_profiles[office_code][profile_id] = profiles_by_id[office_code][profile_id];
                    }
                });
            });

            // sort active profiles by profile.name into a new array
            const sorted_profiles = {};
            Object.keys(active_profiles).forEach(office_code => {
                sorted_profiles[office_code] = Object.keys(active_profiles[office_code]).map(profile_id => {
                    return active_profiles[office_code][profile_id];
                }).sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
            });

            // console.log(sorted_profiles);
            this.profiles_by_id = sorted_profiles;
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
        });

        const pages = this.firebaseService.firebaseAmd.collection<any>('amd').doc('pages');
        pages.valueChanges().subscribe((amd_pages) => {
            this.pages = amd_pages;
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
        });

        const roles = this.firebaseService.firebaseAmd.collection<any>('amd').doc('roles');
        roles.valueChanges().subscribe((amd_roles) => {
            this.roles = amd_roles;
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
        });

        const appt_types_to_roles = this.firebaseService.firebaseAmd.collection<any>('amd').doc('appt-types-to-roles');
        appt_types_to_roles.valueChanges().subscribe((amd_appt_types_to_roles) => {
            this.appt_types_to_roles = amd_appt_types_to_roles;
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
        });
    }

    updateApptTypeCount() {
        // for the given roles, look in appt_types_to_roles and count the number of unique appt types that will be added
        const appt_types = {};
        // appt_types_to_roles is split by office_code, and then is a map of appt_type_id to an array of roles that apply to that appt type
        Object.keys(this.appt_types_to_roles[this.providerUpdate.value.office_code]).forEach(appt_type_id => {
            if (this.appt_types_to_roles[this.providerUpdate.value.office_code][appt_type_id].some(role => this.providerUpdate.value.roles.includes(role))) {
                appt_types[appt_type_id] = true;
            }
        });
        this.number_of_appt_types_to_update = Object.keys(appt_types).length ?? 0;
    }

    onChangeProfile(office_code, profile_object) {
        this.providerUpdate.patchValue({
            office_code: office_code,
            page_id: null,
            facility_id: null,
            column_name: '',
            create_column: false,
            merge_roles: false,
            roles: null,
        });
        this.providerUpdate.controls['create_column'].enable();
        this.providerUpdate.controls['merge_roles'].enable();
        this.providerUpdate.controls['roles'].enable();
        this.resetColumnFields();
    }

    resetColumnFields() {
        if (this.providerUpdate.value.create_column) {
            this.providerUpdate.controls['column_name'].setValidators([Validators.required]);
            this.providerUpdate.controls['page_id'].setValidators([Validators.required]);
            this.providerUpdate.controls['facility_id'].setValidators([Validators.required]);
            this.providerUpdate.controls['column_name'].enable();
            this.providerUpdate.controls['page_id'].enable();
            this.providerUpdate.controls['facility_id'].enable();
        } else {
            this.providerUpdate.controls['column_name'].setValidators([]);
            this.providerUpdate.controls['page_id'].setValidators([]);
            this.providerUpdate.controls['facility_id'].setValidators([]);
            this.providerUpdate.patchValue({
                column_name: '',
                page_id: null,
                facility_id: null,
            });
            // disable the above as well
            this.providerUpdate.controls['column_name'].disable();
            this.providerUpdate.controls['page_id'].disable();
            this.providerUpdate.controls['facility_id'].disable();
        }
    }

    onChangeCreateColumn() {
        this.resetColumnFields();
    }

    updateProvider() {
        console.log(this.providerUpdate.value);
        console.log(this.providerUpdate.valid);
        if (this.providerUpdate.invalid) {
            console.log('Invalid provider update form');
            return;
        }

        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = true;
        dialogConfig.width = '400px';
        dialogConfig.data = {};
        this.openConfirmDialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);

        this.openConfirmDialogRef.afterClosed().subscribe(async confirmed => {
            if (confirmed) {
                console.log('CONFIRMED! Submitting provider update job');
                this.onboardProvider(
                    this.providerUpdate.value.office_code,
                    this.providerUpdate.value.profile_id,
                    this.providerUpdate.value.facility_id,
                    this.providerUpdate.value.column_name,
                    this.providerUpdate.value.page_id,
                    this.providerUpdate.value.roles,
                    this.providerUpdate.value.create_column,
                    this.providerUpdate.value.merge_roles
                );
            } else {
                console.log('GO BACK!');
            }
        });
    }

    onboardProvider(office_code, profile_id, facility_id, column_name, page_id, roles, create_column, merge_roles) {
        const params = {
            office_code,
            profile_id,
            facility_id,
            column_name,
            page_id,
            roles,
            create_column,
            merge_roles,
            password: 'Clarity9404!',
        };
        // remove null or undefined values from params
        Object.keys(params).forEach(key => (params[key] == null || params[key] === undefined) && delete params[key]);

        const url = 'https://update-amd-appointment-type-settings-ozzijyvlya-uc.a.run.app';
        this.http.get(url, {observe : 'response', params: params}).subscribe(result => {
            if (result.status === 200) {
                console.log('JOB FINISHED!');
            } else {
                // this.toastsService.showSnackBar('Error running provider update job', 'error', 5000);
            }
        }, error => {
            console.log(error);
            // let error_message = 'Error running provider update job';
            // if (error?.error?.error) {
            //     error_message = error.error.error;
            // }
            // this.toastsService.showSnackBar(error_message, 'error', 5000);
        });

        this.toastsService.showSnackBar('Provider update job submitted. Check AMD in 10 minutes.', 'success', 5000);

        this.resetProviderUpdateForm();
    }
}
